// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".options_H3nGo{display:flex;flex-direction:column;flex-wrap:wrap;gap:1rem}.dragGhost_npNir{opacity:.1}.colorPicker_TkJJ9{display:flex;flex-direction:row;flex-wrap:wrap;width:100%}.color_ghPBR{border-radius:50%;cursor:pointer;height:2rem;margin:.5rem;transition:all var(--transition-duration-short) ease-in-out;width:2rem}.active_Z104Q{transform:scale(1.25)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options": "options_H3nGo",
	"dragGhost": "dragGhost_npNir",
	"colorPicker": "colorPicker_TkJJ9",
	"color": "color_ghPBR",
	"active": "active_Z104Q"
};
module.exports = ___CSS_LOADER_EXPORT___;
